<template>
  <div
    v-if="isInitialized"
    class="md:h-20 h-36 w-full fixed bottom-0 bg-grayscale-16 z-40 grid md:grid-cols-3 grid-cols-4"
    :class="{ invisible: !isVisible }"
  >
    <div
      class="flex items-center order-1 md:order-none gap-4 col-span-3 md:col-span-1 pl-4 border-t border-grayscale-20 md:border-none"
    >
      <i
        class="icon-pause-2 text-20 cursor-pointer"
        v-if="isPlaying"
        @click.stop="pause(playerStore.provider as TrackProvider)"
      />
      <i
        class="icon-play2 text-20 cursor-pointer"
        v-else
        @click.stop="play(playerStore.provider as TrackProvider)"
      />
      <div class="min-w-0 text-15">
        <p
          class="overflow-hidden overflow-ellipsis whitespace-nowrap font-bold"
        >
          {{ playingTrack.title }}
        </p>

        <p class="overflow-hidden overflow-ellipsis whitespace-nowrap">
          {{ getDisplayName(playingUser) }}
        </p>
      </div>
    </div>
    <div
      class="flex items-center justify-center w-full px-8 max-w-79 col-span-4 md:col-span-1 m-auto gap-12"
    >
      <general-button
        v-if="playerStore.isArchived"
        color="trans"
        height="small"
        @button-click="unArchive"
      >
        Unarchive
      </general-button>
      <template v-else-if="playerStore.isLiked || playerStore.isSaved">
        <ToolTip v-if="playerStore.isSaved" hover>
          <i @click="unSave" class="icon-heart-filled cursor-pointer text-24" />
          <template #content> Remove from Saved</template>
        </ToolTip>
        <ToolTip v-else hover>
          <i @click="save" class="icon-bookmark cursor-pointer text-24" />
          <template #content> Save</template>
        </ToolTip>
        <ToolTip hover>
          <div @click="playerStore.showMessageSubmit = true">
            <i
              v-if="playerStore.hasMessage"
              class="icon-comment-filled cursor-pointer text-24"
            />
            <i v-else class="icon-comment cursor-pointer text-24" />
          </div>
          <template #content> Send message</template>
        </ToolTip>
        <ToolTip hover>
          <i @click="playNext" class="icon-next-track cursor-pointer text-24" />
          <template #content> Next</template>
        </ToolTip>
      </template>
      <template v-else>
        <ToolTip hover>
          <p @click="archive" class="cursor-pointer text-18">❌</p>
          <template #content> Not for me</template>
        </ToolTip>
        <ToolTip hover>
          <p @click="superlike" class="cursor-pointer text-[40px]">❤️‍🔥</p>
          <template #content> This is Fire</template>
        </ToolTip>
        <ToolTip hover>
          <p @click="justLike" class="cursor-pointer text-20">👍</p>
          <template #content> Just send a Like</template>
        </ToolTip>
      </template>
    </div>
    <div
      class="flex items-center justify-end order-2 md:order-none pr-4 border-t border-grayscale-20 md:border-none gap-8"
    >
      <ToolTip hover v-if="playerStore.provider !== TrackProvider.upload">
        <outgoing-link :href="playingTrack.link">
          <i
            class="text-24 cursor-pointer"
            :class="`icon-${
              playerStore.provider === TrackProvider.youtube
                ? 'youtube'
                : playerStore.provider
            }`"
          />
        </outgoing-link>
        <template #content>
          Open in
          {{ getReadableProvider(playerStore.provider as TrackProvider) }}
        </template>
      </ToolTip>
      <ToolTip hover>
        <i
          class="icon-profile text-24 cursor-pointer"
          :class="{ 'text-accent-green': onProfilePage }"
          @click="toggleOnProfilePage"
        />
        <template #content> Profile view</template>
      </ToolTip>
      <ToolTip hover v-if="showSplitButton">
        <p
          :class="{ 'text-accent-green': splitScreen && !onProfilePage }"
          @click="toggleSplitScreen"
        >
          <i v-if="md" class="icon-split-screen text-24 cursor-pointer" />
          <i v-else class="icon-info text-24 cursor-pointer" />
        </p>
        <template #content> Split screen</template>
      </ToolTip>
    </div>

    <div class="w-full h-2 cursor-pointer absolute bottom-0 left-0 right-0">
      <progress
        ref="progressBar"
        min="0"
        max="100"
        :value="playerStore?.barProgress || 0"
      ></progress>
      <input
        ref="seek"
        :value="playerStore?.barProgress || 0"
        min="0"
        max="100"
        type="range"
        class="seek"
        @input="seekTo($event)"
      />
      <div ref="tooltip" class="seek-tooltip">00:00</div>
    </div>

    <Mp3Player
      ref="mp3Player"
      :key="playerStore.previewUrl"
      v-if="provider == 'upload' || provider == 'soundcloud'"
      :previewUrl="playerStore.previewUrl"
      @pause="pause(TrackProvider.upload)"
      @play="play(TrackProvider.upload)"
      @ended="playerStore.playNext()"
      v-model:progress="playerStore.barProgress"
    />

    <YoutubePlayer
      ref="youtubePlayer"
      @pause="pause(TrackProvider.youtube)"
      @play="play(TrackProvider.youtube)"
      @ended="playerStore.playNext()"
      v-model:progress="playerStore.barProgress"
      v-model:isPlaying="playerStore.isPlaying"
    />
    <SpotifyPlayer
      ref="spotifyPlayer"
      @pause="pause(TrackProvider.spotify)"
      @play="play(TrackProvider.spotify)"
      @ended="playerStore.playNext()"
      v-model:progress="playerStore.barProgress"
    />

    <div class="absolute z-50">
      <PlaylistSubmissionSubmitModal
        v-if="playerStore.showMessageSubmit"
        @submit="onMessageSubmit"
        @close="playerStore.showMessageSubmit = false"
        :isLoading="isSubmittingMessage"
      />
      <SignInToSpotifyModal
        v-if="playerStore.showConnectToSpotify"
        @close="playerStore.showConnectToSpotify = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePlayerStore } from "@/store/player";
import { getDisplayName } from "@/utils/display-name";
import ToolTip from "@/components/layout/ToolTip.vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import Mp3Player from "@/components/players/Mp3Player.vue";
import YoutubePlayer from "@/components/players/YoutubePlayer.vue";
import { useLocalNotificationsStore } from "@/store/notifications";
import SpotifyPlayer from "@/components/players/SpotifyPlayer.vue";
import { TrackProvider } from "@/types/track";
import PlaylistSubmissionSubmitModal from "@/components/feedback/pro/components/PlaylistSubmissionSubmit.vue";
import OutgoingLink from "@/components/layout/buttons/OutgoingLink.vue";
import { getReadableProvider } from "@/utils/track-provider";
import AnalyticsService from "@/services/analytics-service";
import SignInToSpotifyModal from "@/components/modal/SignInToSpotifyModal.vue";

const router = useRouter();
const analyticsService = new AnalyticsService("analytics");
const { md } = useBreakpoints();

const playerStore = usePlayerStore();
const localNotifications = useLocalNotificationsStore();

const playingTrack = computed(() => playerStore.playingTrack);
const playingUser = computed(() => playerStore.playingUser);
const whitelistedPages = computed(() =>
  [
    "discover",
    "my-opportunities",
    "my-opportunities-opportunitySlug",
    "artist-uniqueName",
    "dashboard",
    "type-dashboard",
  ].includes(router.currentRoute.value.name as string),
);
const isVisible = computed(
  () => whitelistedPages.value && !!playingTrack.value,
);
const isInitialized = computed(() => playingTrack.value);
const showSplitButton = computed(() =>
  [
    "discover",
    "my-opportunities-opportunitySlug",
    "type-dashboard",
    "artist-uniqueName",
  ].includes(router.currentRoute.value.name as string),
);
const submissionToBeMessaged = ref();

watch(
  () => isVisible.value,
  (newValue) => {
    if (!newValue) {
      pause(playerStore.provider as TrackProvider);
    }
  },
);

watch(
  () => playerStore.shouldPause,
  (newValue) => {
    if (newValue) {
      pause(playerStore.provider as TrackProvider);
      playerStore.shouldPause = false;
    }
  },
);

watch(
  () => playerStore.showMessageSubmit,
  (newValue) => {
    if (newValue) {
      showSubmissionMessageModal();
    }
  },
);

const isSubmittingMessage = ref(false);

const splitScreen = computed({
  get: () => playerStore.splitScreen,
  set: (value) => playerStore.setSplitScreen(value),
});

const isPlaying = computed({
  get: () => playerStore.isPlaying,
  set: (value) => playerStore.setIsPlaying(value),
});

const onProfilePage = computed(() => {
  if (router.currentRoute.value.name == "artist-uniqueName") {
    return true;
  } else {
    return false;
  }
});

const provider = computed(() => playerStore.provider);

const spotifyPlayer = ref<{
  play: () => void;
  pause: () => void;
  seek: (time: number) => void;
}>();
const youtubePlayer = ref<{
  play: () => void;
  pause: () => void;
  seek: (time: number) => void;
}>();
const mp3Player = ref<{
  play: () => void;
  pause: () => void;
  seek: (time: number) => void;
}>();

const seekTo = (event: any) => {
  const percent = parseInt(event.target.value, 10);

  switch (playerStore.provider) {
    case TrackProvider.spotify:
      spotifyPlayer.value?.seek(percent);
      break;
    case TrackProvider.youtube:
      youtubePlayer.value?.seek(percent);
      break;
    case TrackProvider.soundcloud:
    case TrackProvider.upload:
      mp3Player.value?.seek(percent);
      break;
  }
  playerStore.barProgress = percent;
};

const play = (provider: TrackProvider) => {
  isPlaying.value = true;
  switch (provider) {
    case TrackProvider.spotify:
      spotifyPlayer.value?.play();
      break;
    case TrackProvider.youtube:
      youtubePlayer.value?.play();
      break;
    case TrackProvider.soundcloud:
    case TrackProvider.upload:
      mp3Player.value?.play();
      break;
  }
  if (playerStore.pauseStaticPlayer) {
    playerStore.pauseStaticPlayer();
  }
};

const pause = (provider: TrackProvider) => {
  isPlaying.value = false;
  switch (provider) {
    case TrackProvider.spotify:
      spotifyPlayer.value?.pause();
      break;
    case TrackProvider.youtube:
      youtubePlayer.value?.pause();
      break;
    case TrackProvider.soundcloud:
    case TrackProvider.upload:
      mp3Player.value?.pause();
      break;
  }
};

const closeProfile = () => {
  // TODO: not sure what was the flow of this playerStore.reset()
  // if (
  //   ["discover", "my-opportunities"].includes(
  //     (router.options.history.state.back as string).split("?")[0].split("/")[1],
  //   )
  // ) {
  router.back();
  // } else {
  //   playerStore.reset();
  // }
};

const openProfile = () => {
  playerStore.splitScreen = false;
  router.push({
    name: "artist-uniqueName",
    params: { uniqueName: playingUser.value?.uniqueName },
  });
};

const toggleOnProfilePage = () => {
  if (onProfilePage.value) {
    closeProfile();
  } else {
    openProfile();
  }
};

const toggleSplitScreen = () => {
  if (playerStore.mode === "opportunity") {
    analyticsService.trackProViewedSideBar(
      playerStore.opportunitySubmission?.userId!,
      "Opportunity",
    );
    splitScreen.value = !splitScreen.value;
    if (router.currentRoute.value.name !== "my-opportunities-opportunitySlug") {
      splitScreen.value = true;
      router.push({
        name: "my-opportunities-opportunitySlug",
        params: {
          opportunitySlug: playerStore.opportunitySubmission?.opportunity?.slug,
        },
      });
    }
  } else {
    analyticsService.trackProViewedSideBar(
      playerStore.playlistSubmission?.userId!,
      "Music Library",
    );
    splitScreen.value = !splitScreen.value;
    if (router.currentRoute.value.name !== "discover") {
      splitScreen.value = true;
      router.push({
        name: "discover",
      });
    }
  }
};

const archive = async () => {
  await playerStore.archive();
  localNotifications.addNotification({
    message: "Track archived",
    isError: false,
  });
};

const unArchive = async () => {
  await playerStore.unArchive();
  localNotifications.addNotification({
    message: "Track unarchived",
    isError: false,
  });
};

const save = async () => {
  await playerStore.save();
  localNotifications.addNotification({
    message: "Track saved",
    isError: false,
  });
};

const unSave = async () => {
  await playerStore.unSave(
    !!(router.currentRoute.value.query.filter as string),
  );
  localNotifications.addNotification({
    message: "Track unsaved",
    isError: false,
  });
};

const justLike = async () => {
  await playerStore.justLike();
};

const superlike = async () => {
  await playerStore.superlike();
  localNotifications.addNotification({
    message: "Track saved",
    isError: false,
  });
};

const playNext = async () => {
  await playerStore.playNext();
};

const showSubmissionMessageModal = () => {
  if (playerStore.mode === "playlist") {
    submissionToBeMessaged.value = playerStore.playlistSubmission;
  } else {
    submissionToBeMessaged.value = playerStore.opportunitySubmission;
  }
};

const onMessageSubmit = async (message: string) => {
  try {
    isSubmittingMessage.value = true;

    if (playerStore.mode === "playlist") {
      if (submissionToBeMessaged.value) {
        await playerStore.sendPlaylistSubmissionMessage(
          message,
          submissionToBeMessaged.value,
        );
      }
    } else {
      if (submissionToBeMessaged.value) {
        await playerStore.sendOpportunitySubmissionMessage(
          message,
          submissionToBeMessaged.value,
        );
      }
    }
    playerStore.showMessageSubmit = false;
    playerStore.hasMessage = true;
    localNotifications.addNotification({
      message: "Feedback sent!",
      isError: false,
    });
  } catch (error: any) {
    localNotifications.addNotification({
      message: message,
      isError: true,
    });
  } finally {
    isSubmittingMessage.value = false;
  }
};

window.addEventListener("keydown", (event) => {
  const activeElementTagName = document?.activeElement?.tagName.toLowerCase();
  if (activeElementTagName !== "input" && activeElementTagName !== "textarea") {
    if (event.code === "Space") {
      event.preventDefault();
      if (isPlaying.value) {
        pause(playerStore.provider as TrackProvider);
      } else {
        play(playerStore.provider as TrackProvider);
      }
    }
  }
});
</script>

<style scoped>
progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

progress::-webkit-progress-bar {
  background-color: rgba(234, 234, 234, 0.2);
}

progress::-webkit-progress-value {
  background: #f5f5f5;
}

progress::-moz-progress-bar {
  background: #f5f5f5;
}

.seek {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
  height: 8px;
  opacity: 0;
}

.seek:hover + .seek-tooltip {
  display: block;
}

.seek-tooltip {
  display: none;
  position: absolute;
  top: -20px;
  pointer-events: none;
  margin-left: -62px;
  font-size: 8px;
  padding: 2px;
  content: attr(data-title);
  font-weight: bold;
  color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1px;
}

.seek::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 1.3px;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

.seek::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: -1px;
}

.seek:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.seek::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 1.3px;
}

.seek::-moz-range-thumb {
  background: transparent;
  cursor: pointer;
}

.seek:focus::-moz-range-track {
  outline: none;
}
</style>
