<template>
  <div class="flex w-full h-full flex-col md:flex-row">
    <pro-user-view :user="order.pro" />

    <div
      class="flex flex-col pt-16 px-6 xl:pl-40 xl:pr-44 w-full md:h-fill md:overflow-y-auto overflow-x-hidden"
    >
      <div
        v-if="statusInfo.statusTitle"
        :class="[
          'flex w-full items-center border rounded-4 gap-3 px-4',
          rightBtnTitle ? 'min-h-72px' : 'min-h-42px',
          statusInfo.containerClass,
        ]"
      >
        <i
          class="text-base leading-4"
          :class="[statusInfo.leftIcon, statusInfo.leftIconClass]"
        />
        <p class="text-13">
          {{ statusInfo.statusTitle }}
        </p>

        <button
          v-if="rightBtnTitle"
          class="bg-accent-orange text-grayscale-7 flex px-4 rounded-54 items-center h-8 ml-auto"
          @click="rightBtnClick"
        >
          <i
            v-if="rightBtnIcon"
            class="text-24 leading-6 text-grayscale-7"
            :class="rightBtnIcon"
          />
          <p class="ml-1.5 font-semibold text-sm capitalize">
            {{ rightBtnTitle }}
          </p>
        </button>
      </div>

      <div
        v-if="isCallRequest && order.status !== OrderStatus.completed"
        class="flex justify-between py-3 bg-grayscale-10 mt-2 rounded-4"
      >
        <call-bar :pro="false" :request="order" @call-options="callOptions" />
      </div>
      <div
        v-if="order.status === OrderStatus.completed"
        class="flex justify-between py-3 bg-grayscale-10 mt-2 rounded-4"
      >
        <rating-bar
          @rate="isRatingModalVisible = true"
          :rating="order && order.rating"
        />
      </div>
      <div
        v-if="
          order.product?.type === ProductType.video && proFeedback?.mediaUrl
        "
        class="bg-grayscale-13 rounded-8 w-full p-2 max-w-500px mx-auto mt-6"
      >
        <video-preview show-download :url="proFeedback.mediaUrl" />
      </div>
      <div
        v-if="
          order.product?.type === ProductType.audio && proFeedback?.mediaUrl
        "
        class="w-full mx-auto mt-6 bg-card-background px-4 py-3 mb-4 rounded-8"
      >
        <audio-preview
          show-download
          :file="proFeedback.mediaUrl"
          :key="proFeedback.mediaUrl"
        />
      </div>

      <p
        v-if="proFeedback?.textFeedback"
        class="font-semibold text-16 text-grayscale-50 mt-10 whitespace-pre-wrap break-words"
      >
        {{ proFeedback.textFeedback }}
      </p>

      <general-button
        class="py-6 my-8"
        v-if="
          !isCallRequest &&
          order.status === OrderStatus.completed &&
          !order.rating
        "
        @click="isRatingModalVisible = true"
      >
        Rate Your Experience
      </general-button>

      <p
        class="font-semibold tracking-0.15 text-grayscale-96 uppercase mt-16 mb-10"
      >
        {{
          !isCallRequest && order.status !== OrderStatus.completed
            ? `Request for a ${order.feedbackRequest.feedback?.feedbackType} Feedback`
            : "Your initial request"
        }}
      </p>

      <p
        v-if="!isCallRequest && order.status !== OrderStatus.completed"
        class="mb-4 text-16 text-grayscale-96"
      >
        {{
          FeedbackQuestionsByType[order.feedbackRequest.feedback?.feedbackType]
        }}
      </p>

      <p
        class="text-16 text-grayscale-40 mb-10 whitespace-pre-wrap break-words"
      >
        {{ order.feedbackRequest.mainQuestion }}
      </p>
      <div v-if="order.feedbackRequest.extraQuestion">
        <p
          v-if="!isCallRequest && order.status !== OrderStatus.completed"
          class="mb-4 text-16 text-grayscale-96"
        >
          What is something that you’d like {{ getDisplayName(order.pro) }} to
          know about you or your track?
        </p>
        <p
          class="text-16 text-grayscale-40 mb-10 whitespace-pre-wrap break-words"
        >
          {{ order.feedbackRequest.extraQuestion }}
        </p>
      </div>
      <div
        v-if="trackStore.previewLoadingById[order.feedbackRequest.trackId]"
        class="w-full h-20 rounded-8 animate-pulse flex bg-grayscale-40 mt-10"
      ></div>

      <iframe
        v-else-if="
          preview && preview.previewUrl && preview.provider !== 'upload'
        "
        class="w-full h-20 rounded-8 mt-10 mb-auto"
        :src="preview.previewUrl"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      />
      <div
        class="w-full rounded-4"
        v-else-if="preview && preview.provider === 'upload'"
      >
        <div class="bg-grayscale-13 px-4 py-3 mb-4 rounded-8">
          <ClientOnly>
            <AudioPreview
              show-download
              :file="preview.previewUrl"
              :title="preview.title"
              :key="preview.previewUrl"
            />
          </ClientOnly>
        </div>
      </div>
      <PrivatePreview
        v-if="preview && preview.isPrivate"
        :preview-url="preview.previewUrl"
        :thumbnail-url="preview.thumbnailUrl"
        :title="preview.title"
      />
      <div
        class="my-32 flex w-full min-h-76px items-center border-t border-b border-grayscale-20"
      >
        <p class="font-normal text-16 leading-160 text-grayscale-40">
          Request date:
          {{ dayjs(order.feedbackRequest.createdAt).format("MMMM Do") }}
        </p>
      </div>
    </div>
  </div>
  <rate-order-modal
    :order-id="order && order._id"
    :is-call="order && isCall(order)"
    :is-modal-visible="isRatingModalVisible"
    @close="isRatingModalVisible = false"
    :pro-name="`${order.pro.firstName || ''} ${order.pro.lastName || ''}`"
    :pro-profile-picture="order.pro.profilePicture"
    @confirm="orderRated"
  ></rate-order-modal>
</template>

<script setup lang="ts">
import { useTrackStore } from "@/store/track";
import { CallStatus, Order, OrderStatus, ProStatus } from "@/types/order";
import {
  getRemainingMinutes,
  getRemainingTimeCall,
  getRequestDisplayTime,
} from "@/utils/dates";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import ProUserView from "./ProUserView.vue";
import { ProductType } from "@/types/product";
import { useRatingStore } from "@/store/rating";
import dayjs from "dayjs";
import { useFeedbackStore } from "@/store/feedback";
import { Feedback } from "@/types/feedback";
import VideoPreview from "../feedback/Preview.vue";
import AudioPreview from "../feedback/AudioPreview.vue";
import { FeedbackQuestionsByType } from "@/types/feedback";
import { getDisplayName } from "@/utils/display-name";
import CallBar from "../feedback/pro/components/CallBar.vue";
import PrivatePreview from "@/components/feedback/PrivatePreview.vue";
import { useUserStore } from "@/store/user";
import RatingBar from "@/components/feedback/RatingBar.vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import RateOrderModal from "@/components/RateOrderModal.vue";
import { useLocalNotificationsStore } from "@/store/notifications";
import { isCall } from "@/utils/orders";

import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const props = defineProps({
  order: {
    type: Object as PropType<Order>,
    required: true,
  },
});

const router = useRouter();
const order = ref(props.order);

const userStore = useUserStore();
const trackStore = useTrackStore();
const ratingStore = useRatingStore();
const feedbackStore = useFeedbackStore();
const localNotificationsStore = useLocalNotificationsStore();

const preview = ref(
  trackStore.previewById[props.order.feedbackRequest?.trackId || ""],
);
const statusInfo = ref<any>({});
const isRating = ref(false);
const rightBtnTitle = ref("");
const rightBtnIcon = ref<any>();
const remainingMinutes = ref("");
const proFeedback = ref<Feedback>();
const isRatingModalVisible = ref(false);

let timer: any;

const isCallRequest = [ProductType.longCall, ProductType.shortCall].includes(
  props.order.product!.type,
);

const orderRated = async (updatedOrder: Order) => {
  order.value.rating = updatedOrder.rating;
  isRatingModalVisible.value = false;
  localNotificationsStore.addNotification({
    message: "Thank you for your feedback!",
    isError: false,
  });
};

const callOptions = async (type: string) => {
  if (type === "join") {
    const routeData = router.resolve({
      path: `call/${props.order!._id}`,
    });
    window.open(routeData.href, "_blank");
  }
};

const callTime = computed(
  () => props.order.feedbackRequest.call!.callTime?.toString() || "",
);

const remainingHours = computed(() =>
  callTime.value ? getRemainingHours(callTime.value.toString()) : "",
);

const getRemainingHours = (time: string) => {
  const end = dayjs(time);
  const startTime = dayjs();
  const duration = dayjs.duration(end.diff(startTime));
  const hours = Math.floor(duration.asHours());
  remainingMinutes.value = getRemainingMinutes(time);

  return hours.toString();
};

watch(
  () => trackStore.previewById[props.order.feedbackRequest?.trackId || ""],
  (newPreview) => (preview.value = newPreview),
  { immediate: true },
);

onMounted(() => {
  trackStore.getPreviewByTrackId(props.order.feedbackRequest?.trackId);
  resetStatusInfo();
  verifyUserRated();
  verifyProFeedback();
});

const verifyProFeedback = async () => {
  try {
    proFeedback.value = await feedbackStore.getFeedback(props.order._id);
  } catch (err) {
    console.error(err);
  }
};

const verifyUserRated = async () => {
  if (props.order.status === OrderStatus.completed) {
    const currentRating = await ratingStore.verifyRating(
      props.order.feedbackRequest._id!,
      props.order.proId,
    );
    if (!currentRating) {
      isRating.value = true;
      rightBtnTitle.value = "Rate this Pro";
      rightBtnIcon.value = "icon-star";
    }
  }
};

const rightBtnClick = () => {
  if (isRating.value) {
    router.push({
      name: "Rating",
      params: {
        userId: order.value.proId,
        requestId: order.value.feedbackRequest._id,
      },
    });
  } else {
    const routeData = router.resolve({
      name: "Call",
      params: { orderId: order.value._id },
    });
    window.open(routeData.href, "_blank");
  }
};

const resetStatusInfo = () => {
  const order = props.order;

  if (order.status === OrderStatus.completed) {
    const btnTitleByProductType = {
      [ProductType.audio]: "Audio feedback has been completed",
      [ProductType.video]: "Video feedback has been completed",
      [ProductType.written]: "Written feedback has been completed",
      [ProductType.shortCall]: "Call Completed",
      [ProductType.longCall]: "Call Completed",
    };
    statusInfo.value = {
      leftIcon: isCallRequest ? "icon-attention" : "icon-info",
      leftIconClass: "text-accent-green",
      containerClass: "border-accent-green text-accent-green accepted-bg",
      statusTitle: btnTitleByProductType[order.product!.type],
    };
    return;
  }
  if (order.callStatus === CallStatus.active) {
    statusInfo.value = {
      leftIcon: isCallRequest ? "icon-attention" : "icon-info",
      leftIconClass: "text-accent-green",
      containerClass: "border-accent-green text-accent-green accepted-bg",
      statusTitle: "Call Active",
    };
    return;
  }

  if (order.status === OrderStatus.refunded) {
    statusInfo.value = {
      leftIcon: isCallRequest ? "icon-attention" : "icon-info",
      leftIconClass: "text-helpers-validation",
      containerClass:
        "border-helpers-validation text-helpers-validation declined-bg",
      statusTitle: "Refunded",
    };
    return;
  }

  if (order.proStatus === ProStatus.pending) {
    statusInfo.value = {
      leftIcon: "icon-send-watch",
      leftIconClass: "text-accent-blue",
      containerClass: "border-accent-blue text-accent-blue awaiting-bg",
      statusTitle: `${
        isCallRequest
          ? "Awaiting for confirmation"
          : `Awaiting response by ${dayjs(order.dueDate).format("MMMM Do")}`
      } `,
    };
  } else if (order.proStatus === ProStatus.accepted) {
    statusInfo.value = {
      leftIcon: "icon-calendar",
      leftIconClass: "text-accent-green",
      containerClass: "border-accent-green text-accent-green accepted-bg",
      statusTitle: !isCallRequest
        ? getRequestDisplayTime(order.dueDate, userStore.userData.timezone)
        : parseInt(remainingHours.value) > 1
        ? `Scheduled for ${getRemainingTimeCall(
            callTime.value,
            userStore.userData.timezone,
          )}`
        : `Call starts in ${remainingMinutes.value}`,
    };
  }

  if (
    [CallStatus.declined, CallStatus.incompleted].includes(order.callStatus!)
  ) {
    statusInfo.value = {
      leftIcon: "icon-attention",
      leftIconClass: "text-helpers-validation",
      containerClass:
        "border-helpers-validation text-helpers-validation declined-bg",
      statusTitle: `Incompleted. Was due on: ${getRequestDisplayTime(
        order.dueDate,
        userStore.userData.timezone,
      )}`,
    };
    return;
  }
};

onBeforeUnmount(() => {
  timer && clearInterval(timer);
});

defineExpose({
  isRated: () =>
    order.value.status === OrderStatus.completed && !order.value.rating,
  openRatingModal: () => {
    isRatingModalVisible.value = true;
  },
});
</script>

<style scoped>
.awaiting-bg {
  background-color: rgba(71, 123, 255, 0.1);
}

.declined-bg {
  background-color: rgba(255, 0, 0, 0.1);
}

.accepted-bg {
  background-color: rgba(154, 255, 86, 0.1);
}
</style>
