<template>
  <div class="hidden">
    <div id="yt-player"></div>
  </div>
</template>

<script setup lang="ts">
import { checkUntilHasValue } from "@/utils/helpers";
import { usePlayerStore } from "@/store/player";
import { TrackProvider } from "@/types/track";

const playerStore = usePlayerStore();
const props = defineProps({
  progress: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits([
  "play",
  "pause",
  "ended",
  "update:progress",
  "update:isPlaying",
]);

watch(
  () => playerStore.youtubeTrack,
  (value) => {
    if (value) {
      playerStore.youtubePlayer.loadVideoById(value, 0);
    } else {
      pause();
    }
  },
);

watch(
  () => playerStore.replayYoutube,
  (value: boolean) => {
    if (value) {
      player.seekTo(0);
      playerStore.replayYoutube = false;
    }
  },
);

const play = () => {
  if (player) {
    isPlaying.value = true;
    player.playVideo();
  }
};

const pause = () => {
  if (player) {
    isPlaying.value = false;
    player.pauseVideo();
  }
};

const seek = (percent: number) => {
  const calc = (player.getDuration() * percent) / 100;
  if (player) {
    player.seekTo((player.getDuration() * percent) / 100);
  }
};

defineExpose({
  play,
  pause,
  seek,
});

const isPlaying = ref(false);

onMounted(() => {
  if (playerStore.youtubePlayerStatus === 0) {
    playerStore.youtubePlayerStatus = -1;
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
    checkUntilHasValue(["YT", "loaded"], 50, onYoutubeIframeAPIReady);
  } else {
  }
});

const intervalId = setInterval(() => {
  if (player) {
    if (playerStore.provider === TrackProvider.youtube) {
      emit(
        "update:progress",
        (player.getCurrentTime() / player.getDuration()) * 100,
      );
    }
  }
}, 1000);

onUnmounted(() => {
  clearInterval(intervalId);
});

let player: any;

function onPlayerReady(event: any) {
  if (playerStore.provider === TrackProvider.youtube) {
    if (props.progress) {
      seek(props.progress);
      pause();
      emit("pause");
    } else {
      player.playVideo();
      event.target.playVideo();
    }
    isPlaying.value = true;
  }
}

function onPlayerStateChange(event: any) {
  if (event.data == YT.PlayerState.ENDED) {
    emit("ended");
    emit("update:isPlaying", false);
  } else if (event.data == YT.PlayerState.PLAYING) {
    emit("update:isPlaying", true);
    isPlaying.value = true;
  }
}

function onYoutubeIframeAPIReady() {
  playerStore.youtubePlayerStatus = 1;
  player = new YT.Player("yt-player", {
    ...(playerStore.youtubeTrack && { videoId: playerStore.youtubeTrack }),
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });
  playerStore.youtubePlayer = player;
}
</script>
