<template>
  <div
    :class="[
      'fixed bottom-0 right-0 transition-all ease-in-out duration-200 bg-grayscale-7 overflow-y-auto max-h-fill',
      showModal
        ? 'top-12 md:top-14 left-0 bottom-0 right-0'
        : 'bottom-0 right-0 left-0 md:left-full top-full md:top-0',
    ]"
  >
    <div
      v-if="order"
      :class="[
        'transition-all ease-in-out duration-100 relative',
        showContent ? 'opacity-100' : 'opacity-0',
      ]"
    >
      <artist-request-view :order="order" ref="artistRequestView" />

      <button
        @click="close"
        class="absolute top-4 left-4 flex items-center justify-center w-8 h-8 rounded-full bg-grayscale-13"
      >
        <img src="@/assets/icons/back-arrow.svg" class="w-4 h-4" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOrdersStore } from "@/store/orders";
import { Order } from "@/types/order";
import { ref, watch } from "vue";
import ArtistRequestView from "@/components/dashboard/ArtistRequestView.vue";

const ordersStore = useOrdersStore();
const showModal = ref(false);
const showContent = ref(false);
const order = ref(ordersStore.artistOpenedOrder);

watch(
  () => ordersStore.artistOpenedOrder,
  (newOrder?: Order) => {
    if (newOrder) {
      order.value = newOrder;
      showModal.value = true;

      setTimeout(() => (showContent.value = true), 250);
    } else {
      showContent.value = false;
      setTimeout(() => {
        order.value = newOrder;
        showModal.value = false;
      }, 100);
    }
  },
  { immediate: true },
);

const artistRequestView = ref();

const close = () => {
  if (artistRequestView.value && !artistRequestView.value.isRated()) {
    ordersStore.artistOpenOrder();
  } else {
    artistRequestView.value.openRatingModal();
  }
};
</script>
