import { TrackProvider } from "@/types/track";

export const getTrackProvider = (link: string): TrackProvider | null => {
  for (const provider of Object.values(TrackProvider)) {
    if (link.includes(provider)) {
      return provider;
    }
  }
  return null;
};

export const getReadableProvider = (provider: TrackProvider) => {
  switch (provider) {
    case TrackProvider.spotify:
      return "Spotify";
      break;
    case TrackProvider.youtube:
      return "Youtube";
      break;
    case TrackProvider.soundcloud:
      return "Soundcloud";
      break;
  }
};
