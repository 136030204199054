<template>
  <header
    class="sticky top-0 z-50 bg-grayscale-10 h-12 md:h-14 flex justify-between items-center px-4 text-15"
  >
    <div class="flex gap-4 items-center">
      <button
        class="md:hidden"
        id="menu"
        aria-label="menu"
        name="menu-toggle"
        @click="menuOpened = true"
      >
        <i class="icon-hamburger text-20" />
      </button>
      <router-link to="/">
        <image-kit
          height="20"
          width="20"
          path="/logo.svg"
          alt="Xposure Music"
          class="md:hidden"
        />
        <image-kit
          path="/full-logo.svg"
          alt="Xposure Music"
          class="hidden md:block"
        />
      </router-link>
    </div>
    <div
      v-if="user.type == UserType.customer || !isLogged"
      class="hidden md:flex items-center gap-10"
    >
      <!--      <div class="relative py-3" @mouseleave="isDropdownVisible = false">-->
      <!--        <button-->
      <!--          aria-label="Discover Pros"-->
      <!--          @mouseenter="(isDropdownVisible = true), (selectedDropdownItem = 0)"-->
      <!--          class="text-grayscale-96 font-medium text-15 hover:text-accent-orange"-->
      <!--        >-->
      <!--          Discover Pros-->
      <!--          <i :class="`icon-${isDropdownVisible ? 'up' : 'down'}`" />-->
      <!--        </button>-->
      <!--        <div-->
      <!--          v-if="isDropdownVisible"-->
      <!--          class="w-[490px] h-[300px] grid grid-cols-3 absolute top-10 -left-6 drop-shadow-[0_4px_32px_rgba(0,0,0,0.7)] rounded-6 bg-grayscale-10 border-grayscale-16 border"-->
      <!--        >-->
      <!--          <div class="border-grayscale-16 border-r flex flex-col items-center">-->
      <!--            <div-->
      <!--              v-for="(item, ind) in dropdownItems"-->
      <!--              :key="item.title"-->
      <!--              class="py-5 pl-6 border-grayscale-16 border-b w-full"-->
      <!--              :class="selectedDropdownItem === ind ? 'bg-grayscale-16' : ''"-->
      <!--              @mouseenter="selectedDropdownItem = ind"-->
      <!--            >-->
      <!--              {{ item.title }}-->
      <!--            </div>-->
      <!--            <general-button-->
      <!--              class="mt-4"-->
      <!--              height="small"-->
      <!--              color="white"-->
      <!--              redirect="/industry-pros"-->
      <!--              @click="isDropdownVisible = false"-->
      <!--            >-->
      <!--              <i class="icon-arrow-right" />-->
      <!--              All Pros-->
      <!--            </general-button>-->
      <!--          </div>-->
      <!--          <div class="col-span-2 overflow-y-scroll flex flex-col">-->
      <!--            <router-link-->
      <!--              :to="item.redirect"-->
      <!--              v-for="item in dropdownItems[selectedDropdownItem].submenus"-->
      <!--              :key="item.title"-->
      <!--              class="py-5 pl-6 border-grayscale-16 border-b w-full cursor-pointer hover:bg-grayscale-16"-->
      <!--              @click="isDropdownVisible = false"-->
      <!--            >-->
      <!--              {{ item.title }}-->
      <!--            </router-link>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <Link
        href="https://info.xposuremusic.com/funding"
        target="_self"
        class="text-grayscale-96 font-medium text-15 hover:text-accent-orange relative"
      >
        Get Funding
      </Link>
      <Link
        to="/industry-pros"
        class="text-grayscale-96 font-medium text-15 hover:text-accent-orange relative"
        >Connect with Pros</Link
      >
      <Link
        href="https://info.xposuremusic.com/opportunities"
        target="_self"
        class="text-grayscale-96 font-medium text-15 hover:text-accent-orange relative"
      >
        Opportunities
      </Link>
      <Link
        href="https://info.xposuremusic.com"
        target="_self"
        label="Blog"
        class="text-grayscale-96 font-medium text-15 hover:text-accent-orange"
      />
    </div>
    <div class="flex items-center gap-4">
      <ClientOnly>
        <template
          v-if="user.isEnabled || user.type == UserType.customer || !isLogged"
        >
          <general-button
            v-if="isLogged"
            height="small"
            additionalClasses="text-xs dashboard-button"
            color="trans"
            redirect="/dashboard"
          >
            Dashboard
          </general-button>
          <!--          <general-button-->
          <!--            v-else-->
          <!--            height="small"-->
          <!--            additionalClasses="text-xs dashboard-button"-->
          <!--            color="trans"-->
          <!--            @buttonClick="signup"-->
          <!--          >-->
          <!--            Sign Up-->
          <!--          </general-button>-->
        </template>

        <div
          class="relative cursor-pointer"
          v-if="isLogged"
          @click="openProfile"
        >
          <div ref="profile">
            <image-kit
              :path="user.profilePicture || 'placeholder.png'"
              width="24"
              height="24"
              circle
              :alt="`${user.firstName || ''} ${user.lastName || ''}`"
            />
            <span
              v-if="hasUnreadMessages"
              class="w-1.5 h-1.5 bg-accent-green absolute right-0 top-0 rounded-full"
            />
          </div>
          <div
            :class="showProfile ? 'block' : 'hidden'"
            class="transparent-background-overlay bg-grayscale-16 absolute top-9 -right-4 w-screen md:right-1/2 md:max-w-sm flex flex-col gap-[1px] md:rounded-6 border-t md:border border-grayscale-20 md:overflow-hidden md:drop-shadow-[0_4px_32px_rgba(0,0,0,0.7)] z-50"
          >
            <template
              v-if="
                user.isEnabled || user.type == UserType.customer || !isLogged
              "
            >
              <div
                class="p-6 bg-grayscale-10 w-full flex justify-between items-center cursor-auto"
              >
                <p class="font-bold">{{ user.firstName }}</p>
                <a
                  target="_blank"
                  :href="
                    user.type === UserType.pro
                      ? `${baseURL}/${user.uniqueName}`
                      : `${baseURL}/artist/${user.uniqueName}`
                  "
                  class="text-sm text-grayscale-60 flex gap-2 items-center"
                >
                  Show public profile
                  <i class="icon-open-in-new-window" />
                </a>
              </div>

              <router-link
                class="px-6 py-5 hover:bg-grayscale-16 bg-grayscale-10 w-full flex justify-start gap-6 items-center"
                v-for="profileLink in profileLinks"
                :key="profileLink.title"
                :to="profileLink.link"
              >
                <i :class="`icon-${profileLink.icon}`" />
                {{ profileLink.title }}
                <span
                  v-if="profileLink.link === '/chat' && hasUnreadMessages"
                  class="w-1.5 h-1.5 bg-accent-green justify-self-end rounded-full ml-auto"
                />
              </router-link>
            </template>
            <template v-if="!user.isEnabled && user.type === 'pro'">
              <router-link
                class="px-6 py-5 hover:bg-grayscale-16 bg-grayscale-10 w-full flex justify-start gap-6 items-center"
                to="/post-onboarding"
              >
                <i class="icon-profile" />
                Complete your profile
              </router-link>
            </template>
            <div
              @click="logout"
              class="p-6 hover:bg-grayscale-16 bg-grayscale-10 w-full flex justify-between items-center text-sm text-grayscale-60"
            >
              Log out
            </div>
          </div>
        </div>
        <button @click="login" v-else aria-label="Login">
          <i class="icon-profile text-24" />
        </button>
      </ClientOnly>
    </div>

    <div
      v-if="menuOpened"
      class="absolute w-screen h-screen top-0 left-0 bg-grayscale-2 flex flex-col z-[500]"
    >
      <div class="h-56px px-5 flex items-center mb-5">
        <button
          @click="selectedDropdownItemMobile = undefined"
          v-if="selectedDropdownItemMobile != undefined"
          class="flex gap-2 items-center"
        >
          <i class="icon-left text-24" />
          {{ dropdownItems[selectedDropdownItemMobile].title }}
        </button>
        <button
          value="close"
          class="ml-auto"
          @click="
            (menuOpened = false), (selectedDropdownItemMobile = undefined)
          "
        >
          <i class="icon-close text-24" />
        </button>
      </div>
      <div v-if="selectedDropdownItemMobile == undefined" class="flex flex-col">
        <template v-if="user.type == UserType.customer || !isLogged">
          <!--          <div-->
          <!--            class="w-full px-6 py-5 flex justify-between items-center border-grayscale-16 border-y"-->
          <!--          >-->
          <!--            <div>Discover Pros</div>-->
          <!--            <i :class="`icon-${isDiscoverProsCollapsed ? 'down' : 'up'}`" />-->
          <!--          </div>-->
          <!--          <div-->
          <!--            v-for="(item, ind) in dropdownItems"-->
          <!--            @click="selectedDropdownItemMobile = ind"-->
          <!--            class="w-full pl-12 pr-6 py-5 flex justify-between items-center border-grayscale-16 border-y bg-grayscale-10"-->
          <!--          >-->
          <!--            <div>{{ item.title }}</div>-->
          <!--            <i class="icon-down" />-->
          <!--          </div>-->
          <!--          <router-link-->
          <!--            class="w-full pl-12 py-5 flex items-center gap-4 border-grayscale-16 border-y bg-grayscale-10"-->
          <!--            to="/industry-pros"-->
          <!--            height="small"-->
          <!--            color="white"-->
          <!--            redirect="/industry-pros"-->
          <!--            @click="-->
          <!--              (selectedDropdownItemMobile = undefined), (menuOpened = false)-->
          <!--            "-->
          <!--          >-->
          <!--            <i class="icon-arrow-right" />-->
          <!--            All Pros-->
          <!--          </router-link>-->
          <Link
            href="https://info.xposuremusic.com/funding"
            target="_self"
            class="w-full px-6 py-5 flex justify-between items-center border-grayscale-16 border-y"
          >
            Get Funding
          </Link>
          <Link
            to="/industry-pros"
            target="_self"
            class="w-full px-6 py-5 flex justify-between items-center border-grayscale-16 border-y"
            @click="
              (selectedDropdownItemMobile = undefined), (menuOpened = false)
            "
          >
            Connect with Pros
          </Link>
          <Link
            href="https://info.xposuremusic.com/opportunities"
            target="_self"
            class="w-full px-6 py-5 flex justify-between items-center border-grayscale-16 border-y"
          >
            Opportunities
          </Link>
        </template>
        <Link
          href="https://info.xposuremusic.com"
          target="_self"
          label="Blog"
          class="w-full px-6 py-5 flex justify-between items-center border-grayscale-16 border-y"
        />
        <Link
          href="https://support.xposuremusic.com/knowledge"
          label="Support"
          class="w-full px-6 py-5 flex justify-between items-center border-grayscale-16 border-y"
        />
      </div>
      <div v-else class="flex flex-col border-grayscale-16 border-t">
        <router-link
          :to="item.redirect"
          v-for="item in dropdownItems[selectedDropdownItemMobile].submenus"
          :key="item.title"
          class="py-5 pl-6 border-grayscale-16 border-b w-full cursor-pointer hover:bg-grayscale-16"
          @click="
            (selectedDropdownItemMobile = undefined), (menuOpened = false)
          "
        >
          {{ item.title }}
        </router-link>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import ImageKit from "@/components/imagekit/ImageKit.vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import { useAuthenticationStore } from "@/store/authentication";
import { AuthActionTypes } from "@/types/authentication";
import Link from "@/components/layout/buttons/Link.vue";
import { City, Genre, ProJobTitle, UserType } from "@/types/user";
import { slugify } from "@/types/directory";
import { useUserStore } from "@/store/user";
import config from "@/config";
import { useOpportunitySubmissionStore } from "@/store/opportunity-submission";

const router = useRouter();
const authStore = useAuthenticationStore();
const opportunitySubmissionStore = useOpportunitySubmissionStore();
const userStore = useUserStore();
const baseURL = config("baseUrl");

const isDropdownVisible = ref(false);
const menuOpened = ref(false);
const selectedDropdownItem = ref(0);
const selectedDropdownItemMobile = ref();
const isDiscoverProsCollapsed = ref(false);
const showProfile = ref(false);

const profile = ref<HTMLElement>();
const isLogged = computed(() => Boolean(authStore.authUser?.authToken));

const hasUnreadMessages = ref(false);

if (isLogged.value) {
  const chatStore = (await import("@/store/chat")).useChatStore();
  if (!process.server) chatStore.initChatService();

  hasUnreadMessages.value = chatStore.hasUnreadMessages;

  // TODO - remove this watch and use a computed property
  watch(
    () => chatStore.hasUnreadMessages,
    (_hasUnreadMessages) => {
      hasUnreadMessages.value = _hasUnreadMessages;
    },
  );
}

const closeProfile = (e: MouseEvent) => {
  if (profile.value && !profile.value.contains(e.target as Node)) {
    showProfile.value = false;
    window.removeEventListener("click", closeProfile);
  }
};

const openProfile = () => {
  showProfile.value = !showProfile.value;
  nextTick(() => {
    window.addEventListener("click", closeProfile);
  });
};

onUnmounted(() => {
  window.removeEventListener("click", closeProfile);
});

const user = computed(() => userStore.userData);

const dropdownItems = [
  {
    title: "Roles",
    submenus: Object.values(ProJobTitle).map((jobSlug) => {
      return {
        title: jobSlug,
        redirect: `/industry-pros/${slugify(jobSlug)}`,
      };
    }),
  },
  {
    title: "Genres",
    submenus: Object.values(Genre).map((genreSlug) => ({
      title: genreSlug,
      redirect: `/industry-pros/${slugify(genreSlug)}`,
    })),
  },
  {
    title: "Locations",
    submenus: Object.values(City).map((citySlug) => ({
      title: citySlug,
      redirect: `/industry-pros/${slugify(citySlug)}`,
    })),
  },
];

const login = () => {
  router.push("/login");
};

const signup = async () => {
  const analyticsService = new (
    await import("@/services/analytics-service")
  ).default("analytics");

  analyticsService.trackButtonClicked(
    "Get Started",
    "Header",
    "Sign Up",
    Boolean(authStore.authUser?.authToken),
  );
  router.push("/signup");
};

const logout = async () => {
  await router.replace("/");
  const chatStore = (await import("@/store/chat")).useChatStore();
  const trackStore = (await import("@/store/track")).useTrackStore();

  await authStore.logout();
  await (await chatStore.getSendbirdService())!.disconnect();

  trackStore.clearAll();
  opportunitySubmissionStore.clearAll();
};

const profileLinks = computed(() =>
  user.value.type == UserType.pro
    ? [
        {
          title: "Dashboard",
          link: "/dashboard",
          icon: "home",
        },
        {
          title: "1-1 Video Calls",
          link: "/calls",
          icon: "call2",
        },
        {
          title: "Feedback Requests",
          link: "/feedback",
          icon: "feedback",
        },
        {
          title: "Discover Music",
          link: "/discover",
          icon: "radio",
        },
        {
          title: "Chat",
          link: "/chat",
          icon: "comment",
        },
        {
          title: "Profile",
          link: `/my-${user.value.type}-profile`,
          icon: "profile",
        },
      ]
    : [
        {
          title: "Dashboard",
          link: "/dashboard",
          icon: "home",
        },
        {
          title: "My Tracks",
          link: "/music",
          icon: "music",
        },
        {
          title: "Chat",
          link: "/chat",
          icon: "comment",
        },
        {
          title: "My Wishlist",
          link: "/wishlist",
          icon: "bookmark",
        },

        {
          title: "Profile",
          link: `/my-${user.value.type}-profile`,
          icon: "profile",
        },
      ],
);
</script>

<style scoped>
.dashboard-button {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
