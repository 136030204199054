<template>
  <div
    class="w-full md:min-w-375px md:max-w-375px h-auto md:h-screen md:overflow-auto flex flex-col md:border-l md:border-r border-grayscale-13"
  >
    <ProfileImg :user="user" class="new-img-gradient" />

    <div class="p-6 flex flex-col">
      <h1
        class="font-druk-wide font-normal text-24 tracking-0.01 text-grayscale-96 text-center"
      >
        {{ user?.firstName }} {{ user?.lastName }}
      </h1>

      <p
        class="text-center mt-4 font-normal text-15 leading-160 text-grayscale-40"
      >
        {{ getDisplayExperiences(user?.experiences) }}
      </p>

      <socials :user="user" class="flex flex-row gap-2 justify-center mt-6" />

      <router-link
        class="mt-16 rounded-54 h-8 px-11 border border-grayscale-30 hover:border-white mx-auto flex items-center font-semibold text-14 leading-160 tracking-0.01 text-grayscale-40 hover:text-white transition-all ease-in-out duration-200"
        :to="`/chat?user=${user?._id}`"
      >
        Chat
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProSocialLinkProvider, User, WorkExperience } from "@/types/user";
import { PropType } from "vue";
import ProfileImg from "../feedback/pro/components/ProfileImg.vue";
import Socials from "@/components/profile/industry-pro/Socials.vue";

const props = defineProps({
  user: {
    type: Object as PropType<User>,
    required: true,
  },
});

const getDisplayExperiences = (exp?: WorkExperience[]): string => {
  if (!exp?.length) {
    return "";
  }

  return exp
    .map((e) => [e.title, e.company].filter(Boolean).join(" at "))
    .join(", ");
};

const getSocialLinks = (): { link: string; icon: any }[] => {
  const socialLinks = props.user?.socialLinks || {};

  return Object.keys(socialLinks)
    .filter((key: any) => socialLinks[key as ProSocialLinkProvider])
    .map((key: any) => ({
      link: socialLinks[key as ProSocialLinkProvider] as string,
      icon: key,
    }));
};
</script>

<style>
.new-img-gradient .img-gradient {
  background: linear-gradient(
    180deg,
    #111113 0%,
    rgba(23, 23, 25, 0) 100%
  ) !important;
}
</style>
