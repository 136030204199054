<template>
  <div style="visibility: hidden" class="absolute">
    <div id="sp-player"></div>
  </div>
</template>

<script setup lang="ts">
import { checkUntilHasValue } from "@/utils/helpers";
import { usePlayerStore } from "@/store/player";
import { useSessionDataStore } from "@/store/session-data";

const playerStore = usePlayerStore();
const sessionDataStore = useSessionDataStore();
const songLength = ref(0);
const justLoaded = ref(true);

const props = defineProps({
  progress: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["play", "pause", "ended", "update:progress"]);

const play = () => {
  if (player) {
    isPlaying.value = true;
    player.togglePlay();
  }
};

const pause = () => {
  if (player && isPlaying.value) {
    isPlaying.value = false;
    player.togglePlay();
  }
};

const seek = (percent: number) => {
  const calc = Math.floor((songLength.value * percent) / 100000);
  if (player && songLength.value) {
    player.seek(calc);
  }
};

defineExpose({
  play,
  pause,
  seek,
});

watch(
  () => playerStore.spotifyTrack,
  (value, before) => {
    if (value) {
      if (before) {
        pause();
        setTimeout(() => {
          loadSong(value);
        }, 2000);
      } else {
        loadSong(value);
      }
    } else {
      pause();
    }
  },
);

watch(
  () => playerStore.replaySpotify,
  (value: boolean) => {
    if (value) {
      player.play(0);
      playerStore.replaySpotify = false;
    }
  },
);

const isPlaying = ref(false);

const loadSong = (trackId: string) => {
  songLength.value = 0;
  playerStore.spotifyPlayer.loadUri(`spotify:track:${trackId}`);
};

onMounted(() => {
  if (playerStore.spotifyPlayerStatus === 0) {
    window.onSpotifyIframeApiReady = onSpotifyIframeAPIReady;
    playerStore.spotifyPlayerStatus = -1;
    const tag = document.createElement("script");
    tag.src = "https://open.spotify.com/embed-podcast/iframe-api/v1";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
  } else if (playerStore.spotifyTrack && playerStore.spotifyPlayer) {
    player = playerStore.spotifyPlayer;
    player.loadUri(`spotify:track:${playerStore.spotifyTrack}`);
  } else {
    checkUntilHasValue(["SpotifyIframeApi"], 50, onSpotifyIframeAPIReady);
  }
});

let player: any;

function onSpotifyIframeAPIReady(spotifyAPI: any) {
  window.SpotifyIframeApi = spotifyAPI;
  const playerDiv = document.getElementById("sp-player");
  const options = {
    ...(playerStore.spotifyTrack && {
      uri: `spotify:track:${playerStore.spotifyTrack}`,
    }),
  };
  const callback = (el: any) => {
    playerStore.spotifyPlayerStatus = 1;
    playerStore.spotifyPlayer = el;
    player = el;
    player.addListener("ready", playerReady);
    player.addListener("playback_update", playbackUpdate);
  };
  spotifyAPI.createController(playerDiv, options, callback);
}

const playerReady = () => {
  if (justLoaded.value) {
    justLoaded.value = false;
  }
  if (playerStore.autoplay) emit("play");
};

const playbackUpdate = (e) => {
  // 29754 is the exact length of a spotify preview
  if (
    e.data.duration === 29754 &&
    !playerStore.showConnectToSpotify &&
    !sessionDataStore.signInSpotifyShown
  ) {
    playerStore.showConnectToSpotify = true;
    sessionDataStore.signInSpotifyShown = true;
  }
  emit(
    "update:progress",
    ((e.data.position || 0) / (e.data.duration || 0)) * 100,
  );
  if (
    e.data.duration > 0 &&
    e.data.duration === e.data.position &&
    isPlaying.value
  ) {
    isPlaying.value = false;
    playerStore.playNext();
  }
  if (!songLength.value || songLength.value !== e.data.duration) {
    songLength.value = e.data.duration || 1;
  }
};
</script>
