<template>
  <audio hidden="true" ref="audio">
    <source />
  </audio>
</template>

<script setup lang="ts">
import { usePlayerStore } from "@/store/player";

const audio = ref<HTMLAudioElement>();
const playerStore = usePlayerStore();

const props = defineProps({
  previewUrl: {
    type: String,
    required: true,
  },
  progress: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["play", "pause", "ended", "update:progress"]);

const play = () => {
  audio.value?.play();
};

const pause = () => {
  audio.value?.pause();
};

const seek = (percent: number) => {
  audio.value!.currentTime = (audio.value!.duration * percent) / 100;
};

defineExpose({
  play,
  pause,
  seek,
});

watch(
  () => playerStore.replayMp3,
  (value: boolean) => {
    if (value) {
      if (audio.value) {
        audio.value.currentTime = 0;
      }
      playerStore.replayMp3 = false;
    }
  },
);

onMounted(() => {
  if (audio.value) {
    audio.value.src = props.previewUrl;
    audio.value.load();

    audio.value.addEventListener("canplay", async () => {
      audio.value!.play();
      emit("play");
    });

    audio.value.addEventListener("ended", async () => {
      audio.value!.currentTime = 0;

      audio.value!.pause();
      emit("ended");
    });

    audio.value.addEventListener("play", () => {
      emit("play");
    });

    audio.value.addEventListener("pause", () => {
      emit("pause");
    });

    audio.value.addEventListener("timeupdate", () => {
      emit(
        "update:progress",
        ((audio.value?.currentTime || 0) / (audio.value?.duration || 0)) * 100,
      );
    });

    audio.value.addEventListener("ended", () => {
      emit("ended");
    });
  }
});

onBeforeUnmount(() => {
  audio.value?.pause();

  audio.value?.removeEventListener("ended", () => {});
  audio.value?.removeEventListener("play", () => {});
  audio.value?.removeEventListener("pause", () => {});
});
</script>
