<template>
  <sample-modal
    overlay-class="modal-opacity-bg overflow-hidden"
    container-class="rounded-20"
    @close-modal="$emit('close')"
  >
    <template #main>
      <div
        class="sm:h-665px h-screen max-h-screen w-screen md:max-w-484px md:max-h-min flex-1 md:h-auto flex flex-col sm:rounded-20 bg-woodsmoke relative justify-center"
      >
        <button
          id="close-button"
          class="cursor-pointer w-6 h-6 flex items-center justify-center absolute top-4 right-4"
          @click="$emit('close')"
        >
          <img src="@/assets/icons/close.png" alt="close" class="w-3 h-3" />
        </button>

        <div
          class="p-14 sm:p-14 flex flex-col text-center items-center justify-center w-full"
        >
          <img
            class="h-8"
            src="https://ik.imagekit.io/xposuremusic/icons/spotify.svg?updatedAt=1700168166893"
          />
          <h1
            class="text-xl text-grayscale-96 leading-160 font-druk-wide pb-8 pt-10"
          >
            Sign In to Spotify to Hear Full Tracks
          </h1>
          <p class="pb-14">
            Regrettably, Spotify only allows track previews for users who aren't
            logged in. Please sign in to Spotify on this computer to access full
            tracks.
          </p>

          <div class="mt-10 flex w-full justify-center">
            <OutgoingLink
              href="https://accounts.spotify.com/en/login"
              class="flex h-12 w-24 text-sm justify-center items-center font-semibold bg-spotify-green text-grayscale-7 hover:text-grayscale-96 border border-transparent box-border active:border-grayscale-96 rounded-54"
              @click="buttonClicked"
            >
              Sign in
            </OutgoingLink>
          </div>
        </div>
      </div>
    </template>
  </sample-modal>
</template>

<script setup lang="ts">
import SampleModal from "@/components/modal/SampleModal.vue";
import OutgoingLink from "@/components/layout/buttons/OutgoingLink.vue";
import { useLocalNotificationsStore } from "@/store/notifications";

const localNotifications = useLocalNotificationsStore();

const emit = defineEmits(["close"]);

const buttonClicked = () => {
  emit("close");
  localNotifications.addNotification(
    {
      message:
        "Permissions updated. Please refresh the page and play the track to stream it fully",
      isError: false,
    },
    true,
  );
};
</script>
