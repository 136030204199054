<template>
  <div class="w-full relative">
    <div class="flex justify-between gap-4 md:gap-8 w-full px-4">
      <button class="w-8 h-8 p-1.5" @click="toggleMenu">
        <i
          class="text-grayscale-80 icon-hamburger-menu text-20 leading-5 my-auto flex-shrink-0"
        />
      </button>
      <general-button
        v-if="!rating"
        class="bg-accent-orange text-grayscale-7 border border-grayscale-7 flex rounded-54 items-center h-8 shrink-0 px-4"
        @click="$emit('rate')"
      >
        <i class="text-grayscale-7 icon-star text-16 leading-6 shrink-0" />
        <p class="ml-1.5 font-semibold text-sm">Rate</p>
      </general-button>
      <rate-five-stars-preview v-else :stars="rating.stars" />
    </div>

    <div
      v-if="isOpenMenu"
      class="top-8 absolute z-10 bg-grayscale-10 border border-grayscale-13 rounded-8 px-4 py-3 w-max flex flex-col gap-4"
    >
      <div v-for="item in menuList" :key="item.title" class="flex-1">
        <a
          v-if="item.action === 'support'"
          href="mailto:hey@xposuremusic.com"
          class="flex gap-4 pr-2 w-full"
        >
          <i
            :class="`icon-${item.icon} text-grayscale-96 text-20 leading-5 my-auto flex-shrink-0`"
          />
          <p class="text-grayscale-96">{{ item.title }}</p>
        </a>
        <button
          v-else
          class="flex gap-4 pr-2 w-full"
          @click="buttonAction(item.action)"
        >
          <i
            :class="`icon-${item.icon} text-grayscale-96 text-20 leading-5 my-auto flex-shrink-0`"
          />
          <p class="text-grayscale-96">{{ item.title }}</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import RateFiveStarsPreview from "@/components/layout/RateFiveStarsPreview.vue";
import { OrderRating } from "@/types/order";

const isOpenMenu = ref(false);
const emit = defineEmits(["rate"]);

const props = defineProps({
  rating: {
    type: Object as PropType<OrderRating>,
    required: true,
  },
});

const toggleMenu = () => {
  isOpenMenu.value = !isOpenMenu.value;
};
const menuList = computed(() =>
  [
    {
      title: "Contact support",
      icon: "questionmark",
      show: true,
      action: "support",
    },
  ].filter((item) => item.show),
);
</script>
