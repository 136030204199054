<template>
  <div class="default-app-bg">
    <Header v-if="showHeader" />
    <slot />
    <auth-content />
    <div
      v-if="localNotificationsStore.notifications"
      :class="
        playingTrack && whitelistedpages
          ? 'md:bottom-[100px] bottom-[150px]'
          : 'bottom-20 md:bottom-2'
      "
      class="sticky left-0 right-0 mx-auto max-w-96 xl:max-w-1142 w-full z-50 transition-all ease-in-out duration-200"
    >
      <pop-up-notification
        v-for="(notification, index) in localNotificationsStore.notifications"
        :key="notification.id"
        :index="index"
        :is-error="notification.isError"
        :is-visible="notification.isVisible"
        :message="notification.message"
        @close="localNotificationsStore.hideNotification(notification.id)"
      />
    </div>
    <artist-request-view-modal class="z-30" />
    <ClientOnly>
      <BottomPlayer />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import Header from "@/components/layout/Header.vue";
import AuthContent from "@/components/authentication/AuthContent.vue";
import PopUpNotification from "@/components/layout/notifications/PopUpNotification.vue";
import { useLocalNotificationsStore } from "@/store/notifications";
import ArtistRequestViewModal from "@/components/modal/ArtistRequestViewModal.vue";
import BottomPlayer from "@/components/layout/BottomPlayer.vue";
import { usePlayerStore } from "@/store/player";

const router = useRouter();

const localNotificationsStore = useLocalNotificationsStore();
const playerStore = usePlayerStore();

const playingTrack = computed(() => playerStore.playingTrack);

const whitelistedpages = computed(() =>
  [
    "discover",
    "my-opportunities",
    "my-opportunities-opportunitySlug",
    "artist-uniqueName",
    "dashboard",
    "type-dashboard",
  ].includes(router.currentRoute.value.name as string),
);

const showHeader = useSessionStorage("showHeader", true);
</script>
